import { CommonTypes } from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = { BackNav: null, IsOffline: false,IsLoading: false }, action) => {
  switch (action.type) {
    case CommonTypes.AsyncStarted:
      return {
        ...state,
        IsLoading: action.payload ?? true,
      };
    case CommonTypes.AsyncEnd:
      return {
        ...state,
        IsLoading: false,
        Error: null,
      };
    case CommonTypes.AsyncError:
      return {
        ...state,
        IsLoading: false,
        Error: action.payload.Error,
      };
    case CommonTypes.SetOfflineMode:
      return {
        ...state,
        IsOffline: action.payload,
      };
    case CommonTypes.ClearError:
      return {
        ...state,
        Error: null,
        GlobalError: null,
      };
    case CommonTypes.GlobalError:
      return {
        ...state,
        IsLoading: false,
        GlobalError: action.payload.Error,
      };
    case CommonTypes.SetCompany:
      return {
        ...state,
        Company: action.payload,
      };
    case CommonTypes.SetBackNav:
      return {
        ...state,
        BackNav: action.payload,
      };
    case CommonTypes.SetIsDisplayMobile:
      return {
        ...state,
        IsDisplayMobile: action.payload,
      };

    case CommonTypes.SetIsDisplayTablet:
      return {
        ...state,
        IsDisplayTablet: action.payload,
      };
    default:
      return state;
  }
};
