import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './css/LanguagePopup.css';
import LanguageBasic from './LanguageBasic';

const LanguagePopup = ({ show, onHide }) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="language-basic-box"
      size="sm"
      centered
    >
      <Modal.Header closeButton>
        <div>{t('language')}</div>
      </Modal.Header>
      <Modal.Body>
        <LanguageBasic onDone={onHide}></LanguageBasic>
      </Modal.Body>
    </Modal>
  );
};

export default LanguagePopup;
