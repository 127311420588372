export const OrderTypes = {
  ActiveSetTotalCount: 'ORDER.ACTIVE.SETTOTALCOUNT',
  ActiveSetCount: 'ORDER.ACTIVE.SETCOUNT',
  ActiveSetPage: 'ORDER.ACTIVE.SETPAGE',

  InProgressSetTotalCount: 'ORDER.INPROGRESS.SETTOTALCOUNT',
  InProgressSetCount: 'ORDER.INPROGRESS.SETCOUNT',
  InProgressSetPage: 'ORDER.INPROGRESS.SETPAGE',

  ScheduledSetTotalCount: 'ORDER.SCHEDULED.SETTOTALCOUNT',
  ScheduledSetCount: 'ORDER.SCHEDULED.SETCOUNT',
  ScheduledSetPage: 'ORDER.SCHEDULED.SETPAGE',

  CompeletedSetTotalCount: 'ORDER.COMPLETED.SETTOTALCOUNT',
  CompeletedSetCount: 'ORDER.COMPLETED.SETCOUNT',
  CompeletedSetPage: 'ORDER.COMPLETED.SETPAGE',
};
