import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import HeaderMenu from '../common/HeaderMenu';
import MainLayout from '../layout/MainLayout';
import LanguageBasic from './LanguageBasic';
import LanguagePopup from './LanguagePopup';
import './css/LanguageSwitcher.css';
import { useEffect, useState } from 'react';

const LanguageSwitcher = () => {
  const common = useSelector(state => state.common);

  const [canShowPopup, setCanShowPopup] = useState(true);

  useEffect(() => {
    setCanShowPopup(true);
  }, []);

  useEffect(() => {
    setCanShowPopup(common?.IsDisplayMobile || common?.IsDisplayTablet);
  }, [common?.IsDisplayMobile, common?.IsDisplayTablet]);

  const onDoneHandle = () => {};

  const onHideHandle = () => {};

  return common?.IsDisplayMobile || common?.IsDisplayTablet ? (
    <>
      <HeaderMenu></HeaderMenu>
      <div className="language-page">
        <LanguageBasic onDone={onDoneHandle}></LanguageBasic>
      </div>
    </>
  ) : (
    <LanguagePopup show={canShowPopup} onHide={onHideHandle}></LanguagePopup>
  );
};

export default LanguageSwitcher;
