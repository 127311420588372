import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localizationDB from './index.json';


var supportedLocalizations=["en","de","fr"];
// the translations
var resources = {};
for (let i = 0; i < supportedLocalizations.length; i++) {
  const localization = supportedLocalizations[i];
  resources[localization]={translation:{}};
}

for (let key in localizationDB) {
  var localization=localizationDB[key];
  for (let ii = 0; ii < supportedLocalizations.length; ii++) {
    const languageCode = supportedLocalizations[ii];
    resources[languageCode].translation[key]=localization[languageCode]??localization["en"];
  }
}

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng:  'en',
    fallbackLng: 'en',
    debug: false,
  });

export default i18n;
