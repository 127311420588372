import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from '../components/layout/MainLayout';
import LanguageSwitcher from '../components/language/LanguageSwitcher';

const LanaguagesContainer = props => {
  const account = useSelector(state => state.account);

  useEffect(() => {
    if (account?.CurrentUserSession != null) {
    }
  }, [account]);
  return (
    <MainLayout>
      <LanguageSwitcher></LanguageSwitcher>
    </MainLayout>
  );
};

export default LanaguagesContainer;
