import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountAction } from '../../store/actions';
import CustomCheckBox from '../controls/CustomCheckBox';
import OrderService from '../../services/OrderService';

import './css/DailyReportBasic.css';
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';

const DailyReportBasic = ({ onDone }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const account = useSelector(state => state.account);
  const common = useSelector(state => state.common);
  const company = common.Company;

  const setting = account?.Setting;

  const [reports, setReports] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  useEffect(() => {
    console.log('Hello');
    OrderService.GetDailyReport(
      result => {
        console.log('result', result?.Data);
        setReports(result?.Data);
        let total = result?.Data?.reduce((sum, item) => sum + item.Total, 0);
        let count = result?.Data?.reduce((sum, item) => sum + item.Count, 0);
        setTotalCount(count);
        setTotalPrice(total);
      },
      err => {
        console.log('err', err);
      }
    );
  }, []);

  const printOrder = () => {
    var contents = document.getElementById('printableDailyReport');

    var printingIFrame = document.createElement('iframe');
    printingIFrame.name = 'dailyRpPrintingIFrame';
    printingIFrame.style.position = 'absolute';
    printingIFrame.style.top = '-1000000px';
    document.body.appendChild(printingIFrame);
    var frameDoc = printingIFrame.contentWindow
      ? printingIFrame.contentWindow
      : printingIFrame.contentDocument.document
      ? printingIFrame.contentDocument.document
      : printingIFrame.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write('<html><head><title>End of Day Report</title>');
    frameDoc.document.write(
      '</head><style>table tr td,table tr th {border:1px solid #000} table tbody tr td:not(:first-child) {text-align:center} table {width:100%}</style><body>'
    );
    frameDoc.document.write(contents.innerHTML);
    frameDoc.document.write('</body></html>');
    frameDoc.document.close();
    setTimeout(function () {
      window.frames['dailyRpPrintingIFrame'].focus();
      window.frames['dailyRpPrintingIFrame'].print();
      document.body.removeChild(printingIFrame);
    }, 500);
    return false;
  };

  return (
    <div className="daily-report-container">
      {reports && reports.length > 0 && (
        <button className="btn-print" onClick={() => printOrder()}>
          {t('print')}
        </button>
      )}
      <div id="printableDailyReport" className="daily-report-table-container">
        <table>
          <thead>
            <tr>
              <th>{t('payment-method')}</th>
              <th>{t('number-of-order')}</th>
              <th>{t('order-total')}</th>
            </tr>
          </thead>
          <tbody>
            {reports?.map((item, index) => {
              let extraDetail = item.PaymentMethod.match(/\((.*)\)/)?.pop();
              return (
                <tr key={index}>
                  <td>
                    {item.PaymentMethod.replace(/\s*\(.*?\)\s*/g, '')}{' '}
                    {extraDetail && (
                      <>
                        <br />
                        <span>({extraDetail})</span>
                      </>
                    )}
                  </td>
                  <td>{item.Count}</td>
                  <td>
                    <FormattedNumber
                      value={item?.Total}
                      style="currency"
                      currency={company.Currency.Code}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              {common.IsDisplayMobile !== true ? <td></td> : ``}
              <td
                className={
                  common.IsDisplayMobile === true
                    ? `daily-report-total-count-mobile`
                    : ``
                }
              >
                <span>
                  <b>{t('total-number-of-orders')}:</b> {totalCount}
                </span>
              </td>
              <td
                className={
                  common.IsDisplayMobile === true
                    ? `daily-report-total-amount-mobile`
                    : ``
                }
                colSpan={common.IsDisplayMobile !== true ? 1 : 2}
              >
                <span>
                  <b>{t('total-order-amount')}:</b>
                  <FormattedNumber
                    value={totalPrice}
                    style="currency"
                    currency={company.Currency.Code}
                  />
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default DailyReportBasic;
