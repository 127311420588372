import React from 'react';
import './css/button.css';

export const PrimaryButton = ({ children, onClick, disabled, style }) => {
  return (
    <button
      onClick={onClick}
      className="btn-main"
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  );
};

export const DefaultButton = ({ children, onClick, disabled, style }) => {
  return (
    <button
      onClick={onClick}
      className="btn-default"
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  );
};
