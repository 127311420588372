import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './css/NavBars.css';

const NavBars = ({ onSetOrderType }) => {
  const { t } = useTranslation();
  const [key, setKey] = useState('active');
  const setKeyHandle = value => {
    // console.log('setKeyHandle', value);
    setKey(value);
    onSetOrderType(value);
  };
  return (
    <Nav
      variant="pills"
      className="orders-navbars"
      onSelect={k => setKeyHandle(k)}
      activeKey={key}
    >
      <Nav.Item>
        <Nav.Link eventKey="active">{t('active')}</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="scheduled">{t('scheduled')}</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="completed">{t('completed')}</Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default NavBars;
