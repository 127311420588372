import { OrderTypes } from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    ActiveDefaultCount: 10,
    CompeletedDefaultCount: 10,
    InProgressDefaultCount: 20,
    ScheduledDefaultCount: 30,

    ActiveCurrentPage: 0,
    CompeletedCurrentPage: 0,
    InProgressCurrentPage: 0,
    ScheduledCurrentPage: 0,

    ActiveTotalCount: 0,
    CompeletedTotalCount: 0,
    InProgressTotalCount: 0,
    ScheduledTotalCount: 0,
  },
  action
) => {
  switch (action.type) {
    //Set Default Count Query Param
    case OrderTypes.ActiveSetCount:
      return {
        ...state,
        ActiveDefaultCount: action.payload,
      };
    case OrderTypes.CompeletedSetCount:
      return {
        ...state,
        CompeletedDefaultCount: action.payload,
      };
    case OrderTypes.InProgressSetCount:
      return {
        ...state,
        InProgressDefaultCount: action.payload,
      };
    case OrderTypes.ScheduledSetCount:
      return {
        ...state,
        ScheduledDefaultCount: action.payload,
      };

    //Set Total Order Count
    case OrderTypes.ActiveSetTotalCount:
      return {
        ...state,
        ActiveTotalCount: action.payload,
      };
    case OrderTypes.CompeletedSetTotalCount:
      return {
        ...state,
        CompeletedTotalCount: action.payload,
      };
    case OrderTypes.InProgressSetTotalCount:
      return {
        ...state,
        InProgressTotalCount: action.payload,
      };
    case OrderTypes.ScheduledSetTotalCount:
      return {
        ...state,
        ScheduledTotalCount: action.payload,
      };

    //Set Current Page Number
    case OrderTypes.ActiveSetPage:
      return {
        ...state,
        ActiveCurrentPage: action.payload,
      };
    case OrderTypes.CompeletedSetPage:
      return {
        ...state,
        CompeletedCurrentPage: action.payload,
      };
    case OrderTypes.InProgressSetPage:
      return {
        ...state,
        InProgressCurrentPage: action.payload,
      };
    case OrderTypes.ScheduledSetPage:
      return {
        ...state,
        ScheduledCurrentPage: action.payload,
      };
    default:
      return state;
  }
};
