import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from '../components/layout/MainLayout';
import DailyReportSwitcher from '../components/reports/DailyReportSwitcher';

const DailyReportContainer = props => {
  const account = useSelector(state => state.account);

  useEffect(() => {
    if (account?.CurrentUserSession != null) {
    }
  }, [account]);
  return (
    <MainLayout>
      <DailyReportSwitcher></DailyReportSwitcher>
    </MainLayout>
  );
};

export default DailyReportContainer;
