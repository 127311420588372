import { OrderTypes } from '../types';
import ActionHelper from '../helpers/ActionHelper';

const setOrderDefaultCount = ({ key, value }) => {
  key = key?.toLowerCase();
  let actionName;
  switch (key) {
    case 'active':
      actionName = OrderTypes.ActiveSetCount;
      break;
    case 'inprogress':
      actionName = OrderTypes.InProgressSetCount;
      break;
    case 'completed':
      actionName = OrderTypes.CompeletedSetCount;
      break;
    case 'scheduled':
      actionName = OrderTypes.ScheduleddSetCount;
      break;
    default:
      return;
  }
  return ActionHelper.returnAction(actionName, value);
};

const setOrderCurrentPage = ({ key, value }) => {
  key = key?.toLowerCase();
  let actionName;
  switch (key) {
    case 'active':
      actionName = OrderTypes.ActiveSetPage;
      break;
    case 'inprogress':
      actionName = OrderTypes.InProgressSetPage;
      break;
    case 'completed':
      actionName = OrderTypes.CompeletedSetPage;
      break;
    case 'scheduled':
      actionName = OrderTypes.ScheduledSetPage;
      break;
    default:
      return;
  }
  return ActionHelper.returnAction(actionName, value);
};

const setOrderTotalCount = ({ key, value }) => {
  key = key?.toLowerCase();
  let actionName;
  switch (key) {
    case 'active':
      actionName = OrderTypes.ActiveSetTotalCount;
      break;
    case 'inprogress':
      actionName = OrderTypes.InProgressSetTotalCount;
      break;
    case 'completed':
      actionName = OrderTypes.CompeletedSetTotalCount;
      break;
    case 'scheduled':
      actionName = OrderTypes.ScheduledSetTotalCount;
      break;
    default:
      return;
  }
  return ActionHelper.returnAction(actionName, value);
};

export const OrderAction = {
  setOrderDefaultCount,
  setOrderCurrentPage,
  setOrderTotalCount,
};
