export const getAPIErrorMessage = errorCode => {
  console.info('getting error code from the API: ' + errorCode);
  switch (errorCode) {
    case 3:
      return 'Email is invalid';
    case 5:
      return 'Validation code is invalid';
    case 10:
      return 'Sorry, The Mobile Number Already Registered';
    case 11:
      return 'Sorry, The Email Already Registered';
    case 20:
      return 'Sorry, login failed, Please check entered username and password';
    case 21:
      return 'Sorry, session expired';
    case 33:
      return 'Sorry, The amount should be equal to or greater than the Delivery minimum amount';
    case 37:
      return 'Sorry, The Coupon Code Invalid';
    case 9000:
      return 'Sorry, No restaurants near your location for delivery!';
    default:
      return 'Error!';
  }
};
