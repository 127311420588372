import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter, Route, Router, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { PersistGate } from 'redux-persist/integration/react';
import ReportWebVitals from './ReportWebVitals';
import storeManager from './store';
import DashboardContainer from './containers/DashboardContainer';

import './localization/i18n';

import 'bootstrap/dist/css/bootstrap.min.css';
import './containers/css/Index.css';
import OrdersContainer from './containers/OrdersContainer';
import SettingContainer from './containers/SettingContainer';
import DailyReportContainer from './containers/DailyReportContainer';
import LanaguagesContainer from './containers/LanaguagesContainer';

import * as serviceWorker from './RegisterServiceWorker';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container);


root.render(
  <Provider store={storeManager.createStore()}>
    <PersistGate loading={null} persistor={storeManager.persistor}>
      <IntlProvider>
        <HashRouter basename='/' history={storeManager.history}>
          <Routes>
            <Route path="/" element={<DashboardContainer />} />
            <Route path="Orders" element={<OrdersContainer />} />
            <Route path="Settings" element={<SettingContainer />} />
            <Route path="Language" element={<LanaguagesContainer />} />
            <Route path="DailyReport" element={<DailyReportContainer />} />
            <Route path="Login" element={<DashboardContainer />} />
          </Routes>
        </HashRouter>
      </IntlProvider>
    </PersistGate>
  </Provider>
);

ReportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
