import { AccountTypes } from '../types';
import ActionHelper from '../helpers/ActionHelper';

var login = user => {
  return ActionHelper.returnAction(AccountTypes.Login, user);
};

var logout = () => {
  return ActionHelper.returnAction(AccountTypes.Logout);
};

var setSetting = setting => {
  return ActionHelper.returnAction(AccountTypes.SetSetting, setting);
};

var setLanguage = lang => {
  return ActionHelper.returnAction(AccountTypes.SetLang, lang);
};

export const AccountAction = {
  login,
  logout,
  setSetting,
  setLanguage,
};

export default AccountAction;
