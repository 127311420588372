import ApiHelper from '../store/helpers/ApiHelper';
import AccountApi from './api/Account';

const Login = (email, password, successCallback, errorCallback) => {
  return ApiHelper.postAsync({
    url: AccountApi.Login,
    param: { email, password },
    errorCallback: errorCallback,
    afterCallback: successCallback,
  });
};

const Profile = (successCallback, errorCallback) => {
  return ApiHelper.getAsync({
    url: AccountApi.Profile,
    param: {},
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
  });
};

const AccountService = {
  Login,
  Profile,
};

export default AccountService;
