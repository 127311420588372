import React, { useEffect, useState } from 'react';
import './css/OrdersContainer.css';
import { Container, Row, Col } from 'react-bootstrap';
import MainLayout from '../components/layout/MainLayout';
import HeaderMenu from '../components/common/HeaderMenu';
import NavBars from '../components/orders/NavBars';
import OrderList from '../components/orders/OrderList';
import CardDetail from '../components/orders/CardDetail';

import OrderService from '../services/OrderService';
import { CommonAction, OrderAction } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';

import notifySound from '../contents/common/Alert-Ring.mp3';
import { useTranslation } from 'react-i18next';

const OrdersContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const common = useSelector(state => state.common);
  const account = useSelector(state => state.account);

  useEffect(() => {
    if (account.CurrentSession !== null) {
      return;
    }
  }, [account]);

  const [orderType, setOrderType] = useState('active');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [orderListShowLoading, setOrderListShowLoading] = useState(false);
  const [orderListInProgressShowLoading, setOrderListInProgressShowLoading] =
    useState(false);

  const [isShowLoadingSelectedOrder, setIsShowLoadingSelectedOrder] =
    useState(false);
  const [orderList, setOrderList] = useState([]);
  const [inProgressOrderList, setInProgressOrderList] = useState([]);
  const [activeOrderCount, setActiveOrderCount] = useState(0);
  const [inProgressOrderCount, setInProgressOrderCount] = useState(0);
  const [currentOrderPageIndex, setCurrentOrderPageIndex] = useState(1);
  const [currentInProgressOrderPageIndex, setCurrentInProgressOrderPageIndex] =
    useState(1);


    const acceptOrders = async (currentOrders) => {
    var promises = currentOrders.map(i =>
      acceptAndConfirmOrder(i.ID, i.Eta)
    );

    await Promise.all(promises).then(() => {
      console.log('Map Opration Successfully Completed');
      getOrderListCount();
      getOrderList({ selectedOrderType: 'active', pageIndex: 1 });
    });
  };

  const getOrderList = ({
    selectedOrderType,
    fetchedRowsLength = window.innerHeight > 1300 ? 20 : 10,
    mainLoading,
    isItPaginationRequest = false,
    pageIndex = null,
  }) => {
    if (account.CurrentUserSession === null) {
      return;
    }
    //console.log('mainLoading', mainLoading, selectedOrderType);
    switch (selectedOrderType) {
      case 'active':
        {
          setOrderListShowLoading(
            mainLoading !== undefined ? mainLoading : true
          );
          OrderService.Pending(
            {
              pageSize: fetchedRowsLength,
              pageIndex: pageIndex ? pageIndex : currentOrderPageIndex,
            },
            result => {
              const rsOrders = result.Data ? result.Data : result;
              let currentOrders = [];
              if (!isItPaginationRequest) {
                currentOrders = rsOrders;
                setOrderList(rsOrders);
                setCurrentOrderPageIndex(2);
              } else {
                if (selectedOrderType === orderType) {
                  let lst = [];
                  // lst = orderList;

                  rsOrders.map(i => lst.push(i));
                  orderList.map(i => lst.push(i));
                  setOrderList(lst);
                  currentOrders = lst;
                  setCurrentOrderPageIndex(currentOrderPageIndex + 1);
                } else {
                  setOrderList(rsOrders);
                  currentOrders = rsOrders;
                  setCurrentOrderPageIndex(2);
                }
              }

              if (
                currentOrders &&
                currentOrders.length > 0 &&
                account?.Setting?.AutoAcceptOrders === true
              ) {
                
                  currentOrders.map(order =>{
                    switch(order.OrderMethod) {
                      case 1:
                        order.Eta = account?.Setting?.DeliveryDefaultMin
                        ? account?.Setting?.DeliveryDefaultMin
                        : 0;
                        break;
                      case 2:
                        order.Eta = account?.Setting?.PickupDefaultMin
                        ? account?.Setting?.PickupDefaultMin
                        : 0;
                        break;
                      case 3:
                        order.Eta = account?.Setting?.EatDefaultMin
                        ? account?.Setting?.EatDefaultMin
                        : 0;
                        break;
                      case 4:
                        order.Eta = account?.Setting?.TableOrderDefaultMin
                        ? account?.Setting?.TableOrderDefaultMin
                        : 0;
                        break;
                      case 5:
                        order.Eta = account?.Setting?.RoomServiceDefaultMin
                        ? account?.Setting?.RoomServiceDefaultMin
                        : 0;
                        break;
                      case 6:
                        order.Eta = account?.Setting?.SeatServiceDefaultMin
                        ? account?.Setting?.SeatServiceDefaultMin
                        : 0;
                        break;
                      default:
                        order.Eta = 0
                    }
                  });

                acceptOrders(currentOrders);
              }

              onSelectedOrderHandle();

              setOrderListShowLoading(false);
            }
          );
        }
        break;
      case 'scheduled':
        {
          setOrderListShowLoading(
            mainLoading !== undefined ? mainLoading : true
          );
          OrderService.Schedule(
            {
              pageSize: fetchedRowsLength,
              pageIndex: pageIndex ? pageIndex : currentOrderPageIndex,
            },
            result => {
              const rsOrders = result.Data ? result.Data : result;
              if (!isItPaginationRequest) {
                setOrderList(rsOrders);
                setCurrentOrderPageIndex(2);
              } else {
                if (selectedOrderType === orderType) {
                  let lst = [];
                  // lst = orderList;

                  rsOrders.map(i => lst.push(i));
                  orderList.map(i => lst.push(i));
                  setOrderList(lst);
                  setCurrentOrderPageIndex(currentOrderPageIndex + 1);
                } else {
                  setOrderList(rsOrders);
                  setCurrentOrderPageIndex(2);
                }
              }
              onSelectedOrderHandle();

              setOrderListShowLoading(false);
            }
          );
        }

        break;
      case 'completed':
        {
          setOrderListShowLoading(
            mainLoading !== undefined ? mainLoading : true
          );
          OrderService.Completed(
            {
              pageSize: fetchedRowsLength,
              pageIndex: pageIndex ? pageIndex : currentOrderPageIndex,
            },
            result => {
              const rsOrders = result.Data ? result.Data : result;
              if (!isItPaginationRequest) {
                setOrderList(rsOrders);
                setCurrentOrderPageIndex(2);
              } else {
                if (selectedOrderType === orderType) {
                  let lst = [];
                  // lst = orderList;

                  rsOrders.map(i => lst.push(i));
                  orderList.map(i => lst.push(i));
                  setOrderList(lst);
                  setCurrentOrderPageIndex(currentOrderPageIndex + 1);
                } else {
                  setOrderList(rsOrders);
                  setCurrentOrderPageIndex(2);
                }
              }
              onSelectedOrderHandle();

              setOrderListShowLoading(false);
            }
          );
        }
        break;

      case 'inprogress':
        {
          setOrderListInProgressShowLoading(
            mainLoading !== undefined ? mainLoading : true
          );
          OrderService.InProgress(
            {
              pageSize: fetchedRowsLength,
              pageIndex: pageIndex
                ? pageIndex
                : currentInProgressOrderPageIndex,
            },
            result => {
              const rsOrders = result.Data ? result.Data : result;
              if (!isItPaginationRequest) {
                setInProgressOrderList(rsOrders);
                setCurrentInProgressOrderPageIndex(2);
              } else {
                if (selectedOrderType === 'inprogress') {
                  let lst = [];
                  // lst = orderList;

                  rsOrders.map(i => lst.push(i));
                  inProgressOrderList.map(i => lst.push(i));
                  setInProgressOrderList(lst);
                  setCurrentInProgressOrderPageIndex(
                    currentInProgressOrderPageIndex + 1
                  );
                } else {
                  setInProgressOrderList(rsOrders);
                  setCurrentInProgressOrderPageIndex(2);
                }
                onSelectedOrderHandle();
              }

              setOrderListInProgressShowLoading(false);
            }
          );
        }
        break;

      default:
        break;
    }
  };

  const showNotification = ({ title, message }) => {
    const options = {
      body: message,
      icon: '',
      dir: 'ltr',
    };
    if (account && account.Setting && account.Setting.HideAlertPopup !== null) {
      if (account?.Setting?.HideAlertPopup !== true) {
        if (account?.Setting?.MuteAlert !== true) {
          new Audio(notifySound).play();
        }

        navigator.serviceWorker.ready.then(reg => {
          console.log('is Ready! 1');
          reg.showNotification('your arguments goes here 1');
        });

        // if (window.Notification && Notification.permission == 'granted') {
        //   // We would only have prompted the user for permission if new
        //   // Notification was supported (see below), so assume it is supported.
        //   console.log('Order Page Notification : Has Granted');
        //   new Notification(title, options);
        // } else if (isNewNotificationSupported()) {
        //   // new Notification is supported, so prompt the user for permission.
        //   console.log('Order Page Notification : Get new Granted');
        //   new Notification(title, options);
        // }
        if (!('Notification' in window)) {
          console.log('This browser does not support desktop notification');
        } else {
          Notification.requestPermission();
          new Notification(title, options);
        }
      }
    } else {
      if (
        account.Setting &&
        account.Setting &&
        account.Setting.MuteAlert !== null
      ) {
        if (account.Setting.MuteAlert === false) {
          new Audio(notifySound).play();
        }
      } else {
        new Audio(notifySound).play();
      }

      navigator.serviceWorker.ready.then(reg => {
        console.log('is Ready! 2');
        reg.showNotification('your arguments goes here');
      });
      if (!('Notification' in window)) {
        console.log('This browser does not support desktop notification');
      } else {
        Notification.requestPermission();
        new Notification(title, options);
      }
      // if (window.Notification && Notification.permission == 'granted') {
      //   // We would only have prompted the user for permission if new
      //   // Notification was supported (see below), so assume it is supported.
      //   console.log('Order Page Notification : Has Granted');
      //   new Notification(title, options);
      // } else if (isNewNotificationSupported()) {
      //   // new Notification is supported, so prompt the user for permission.
      //   console.log('Order Page Notification : Get new Granted');
      //   new Notification(title, options);
      // }
    }
  };

  const getOrderListCount = () => {
    setActiveOrderCount(0);
    setInProgressOrderCount(0);
    dispatch(
      OrderAction.setOrderTotalCount({
        key: 'active',
        value: 0,
      })
    );
    dispatch(
      OrderAction.setOrderTotalCount({
        key: 'inprogress',
        value: 0,
      })
    );
    if (account.CurrentUserSession === null) {
      return;
    }
    OrderService.TotalCount(result => {
      setActiveOrderCount(result?.Data?.Active);
      setInProgressOrderCount(result?.Data?.Processing);

      dispatch(
        OrderAction.setOrderTotalCount({
          key: 'active',
          value: result?.Data?.Active,
        })
      );
      dispatch(
        OrderAction.setOrderTotalCount({
          key: 'inprogress',
          value: result?.Data?.Processing,
        })
      );

      if (result?.Data?.Active !== activeOrderCount && orderType === 'active') {
        getOrderList({ selectedOrderType: orderType, pageIndex: 1 });
        if (result?.Data?.Active > activeOrderCount)
          showNotification({
            title: t('new-order'),
            message: t('pending-orders-message'),
          });
        // new Notification('Hey! A New Order Recived!');
      }

      if (result?.Data?.Processing !== inProgressOrderCount) {
        getOrderList({ selectedOrderType: 'inprogress', pageIndex: 1 });
        // if (result?.Data?.Processing > inProgressOrderCount)
        //   showNotification({
        //     title: 'In Progress',
        //     message: 'The in progress orders list updated!',
        //   });
        //new Notification('Hey There is a new order in progress!');
      }
    });
  };

  useEffect(() => {
    const intervalGetListOrder = setInterval(() => {
      getOrderListCount();
    }, 15000);
    return () => clearInterval(intervalGetListOrder);
  }, [inProgressOrderCount, activeOrderCount, orderType]);

  useEffect(() => {
    const intervalPlaySoundUntilActiveOrder = setInterval(() => {
      if (activeOrderCount > 0) {
        if (
          account.Setting &&
          account.Setting &&
          account.Setting.MuteAlert !== null
        ) {
          if (account.Setting.MuteAlert === false) {
            new Audio(notifySound).play();
          }
        } else {
          new Audio(notifySound).play();
        }
      }
    }, 3000);
    return () => clearInterval(intervalPlaySoundUntilActiveOrder);
  }, [activeOrderCount, account?.Setting]);

  useEffect(() => {
    getOrderListCount();
    getOrderList({ selectedOrderType: orderType });
    getOrderList({ selectedOrderType: 'inprogress' });
  }, [common?.IsOffline]);

  const setOrderTypeHandle = value => {
    setOrderType(value);
    setCurrentOrderPageIndex(1);
    getOrderList({ selectedOrderType: value, pageIndex: 1 });
  };

  const onSelectedOrderHandle = (obj, checkBackNav = false) => {
    if ((common?.IsDisplayMobile || common?.IsDisplayTablet) && checkBackNav)
      dispatch(CommonAction.setBackNav('Orders'));

    setSelectedOrderId(obj);
    if (obj) {
      // if (obj.orderType === 'inprogress')
      //   setSelectedOrder(inProgressOrderList.find(p => p.ID === obj.value));
      // else setSelectedOrder(orderList.find(p => p.ID === obj.value));
      setIsShowLoadingSelectedOrder(true);
      OrderService.Detail(
        obj.value,
        result => {
          //console.log('Detail', result);
          setSelectedOrder(result.Data ? result.Data : result);
          setIsShowLoadingSelectedOrder(false);
        },
        err => {
          //console.log('detail err', err);
          setIsShowLoadingSelectedOrder(false);
        }
      );
    } else {
      setSelectedOrder(null);
    }
  };

  const onSelectedOrderHandleOrderList = obj => {
    onSelectedOrderHandle(obj, true);
  };

  const onReloadListHanlder = id => {
    //console.log('onReloadListHanlder fired!', selectedOrderId.orderType);
    getOrderListCount();
    getOrderList({
      selectedOrderType: selectedOrderId.orderType,
      pageIndex: 1,
    });
  };

  const onUpdateStateHandle = value => {
    getOrderListCount();
    getOrderList({ selectedOrderType: value.orderType, pageIndex: 1 });
  };

  const fetchMoreDataHandle = value => {
    getOrderList(value);
  };

  const acceptAndConfirmOrder = (id, eta = 0) => {
    OrderService.AcceptOrder(
      { OrderID: id, Eta: eta },
      result => {
        // onReloadListHanlder();
      },
      error => {
        console.log(error);
      }
    );
  };
  return (
    <MainLayout>
      <HeaderMenu></HeaderMenu>
      <Container fluid className="orders-container">
        <Row>
          {(common?.IsDisplayMobile || common?.IsDisplayTablet) &&
          common?.BackNav ? (
            ``
          ) : (
            <>
              <Col lg={4} className="orders-navbars parent">
                <NavBars onSetOrderType={setOrderTypeHandle}></NavBars>
                <OrderList
                  isDispalyMobile={common?.IsDisplayMobile}
                  isDispalyTablet={common?.IsDisplayTablet}
                  showLoading={orderListShowLoading}
                  onSelectedOrder={onSelectedOrderHandleOrderList}
                  selectedOrderId={
                    selectedOrderId?.orderType !== 'inprogress'
                      ? selectedOrderId?.value
                      : null
                  }
                  orderType={orderType}
                  orderList={orderList}
                  fetchMoreData={fetchMoreDataHandle}
                  totalOrder={orderType === 'active' ? activeOrderCount : 10}
                ></OrderList>
              </Col>

              {orderType !== 'active' &&
              (common?.IsDisplayMobile || common?.IsDisplayTablet) ? (
                ` `
              ) : (
                <Col className="in-progress-section parent" lg={4}>
                  <div className="title">
                   {t('in-progress')} ({inProgressOrderCount})
                  </div>
                  <OrderList
                    isDispalyMobile={common?.IsDisplayMobile}
                    isDispalyTablet={common?.IsDisplayTablet}
                    showLoading={orderListInProgressShowLoading}
                    onSelectedOrder={onSelectedOrderHandleOrderList}
                    orderType={'InProgress'}
                    orderList={inProgressOrderList}
                    selectedOrderId={
                      selectedOrderId?.orderType === 'inprogress'
                        ? selectedOrderId?.value
                        : null
                    }
                    fetchMoreData={fetchMoreDataHandle}
                    totalOrder={inProgressOrderCount}
                    onUpdateState={onUpdateStateHandle}
                  ></OrderList>
                </Col>
              )}
            </>
          )}
          {common?.IsDisplayMobile || common?.IsDisplayTablet ? (
            common?.BackNav === 'Orders' ? (
              <Col className="card-detail-section parent" lg={4}>
                <CardDetail
                  isDispalyMobile={common?.IsDisplayMobile}
                  isDispalyTablet={common?.IsDisplayTablet}
                  showLoading={isShowLoadingSelectedOrder}
                  order={selectedOrder}
                  onReloadList={onReloadListHanlder}
                ></CardDetail>
              </Col>
            ) : (
              ''
            )
          ) : (
            <Col className="card-detail-section parent" lg={4}>
              <CardDetail
                isDispalyMobile={common?.IsDisplayMobile}
                isDispalyTablet={common?.IsDisplayTablet}
                showLoading={isShowLoadingSelectedOrder}
                order={selectedOrder}
                onReloadList={onReloadListHanlder}
              ></CardDetail>
            </Col>
          )}
        </Row>
      </Container>
    </MainLayout>
  );
};

export default OrdersContainer;
