import deliveryIcon from '../contents/ordermethod/delivery.svg';
import pickupIcon from '../contents/ordermethod/takeaway.svg';

export default {
    delivery: {
        id: 1,
        title: 'Delivery',
        description: 'I want to order for delivery',
        icon: deliveryIcon,
        translateKey: 'ORDER_METHOD_DELIVERY'
    },
    pickup: {
        id: 2,
        title: 'Pickup',
        description: 'I want to collect my order',
        icon: pickupIcon,
        translateKey: 'ORDER_METHOD_PICKUP'
    },
    eatIn: {
        id: 3,
        title: 'Eat-In',
        description: 'I want to order for eat-in',
        icon: deliveryIcon,
        translateKey: 'ORDER_METHOD_EATIN'
    },
    tableOrder: {
        id: 4,
        title: 'Table Order',
        description: 'I want to order to my table',
        icon: deliveryIcon,
        translateKey: 'ORDER_METHOD_TABLEORDER'
    },
    roomService: {
        id: 5,
        title: 'Room Service',
        description: 'I want to order to my room',
        icon: deliveryIcon,
        translateKey: 'ORDER_METHOD_ROOMSERVICE'
    },
    seatService: {
        id: 6,
        title: 'Seat Service',
        description: 'I want to order to my seat',
        icon: deliveryIcon,
        translateKey: 'ORDER_METHOD_SEATSERVICE'
    }
};