import LoginInSection from '../components/account/LoginInSection';
import './css/AuthContainer.css';
import { Container } from 'react-bootstrap';
import logoIco from '../contents/common/logo.svg';

const AuthContainer = () => {
  return (
    <Container className="auth-container">
      <div className="logo">
        <img src={logoIco} alt="logo"></img>
      </div>
      <div className="login-form-section">
        <LoginInSection></LoginInSection>
      </div>
    </Container>
  );
};

export default AuthContainer;
