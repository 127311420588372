import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './css/SettingPopup.css';
import SettingBasic from './SettingBasic';

const SettingPopup = ({ show, onHide }) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="setting-basic-box"
      size="sm"
      centered
    >
      <Modal.Header closeButton>
        <div>{t('sidebar-menu-setting')}</div>
      </Modal.Header>
      <Modal.Body>
        <SettingBasic onDone={onHide}></SettingBasic>
      </Modal.Body>
    </Modal>
  );
};

export default SettingPopup;
