import { useSelector } from 'react-redux';
import HeaderMenu from '../common/HeaderMenu';
import DailyReportBasic from './DailyReportBasic';

import './css/DailyReportSwitcher.css';
import { useEffect, useState } from 'react';

const DailyReportSwitcher = () => {
  const common = useSelector(state => state.common);

  const [canShowPopup, setCanShowPopup] = useState(true);

  useEffect(() => {
    setCanShowPopup(true);
  }, []);

  useEffect(() => {
    setCanShowPopup(common?.IsDisplayMobile || common?.IsDisplayTablet);
  }, [common?.IsDisplayMobile, common?.IsDisplayTablet]);

  const onDoneHandle = () => {};

  const onHideHandle = () => {};

  return (
    <>
      <HeaderMenu></HeaderMenu>
      <div className="daily-report-page">
        <DailyReportBasic onDone={onDoneHandle}></DailyReportBasic>
      </div>
    </>
  );
};

export default DailyReportSwitcher;
