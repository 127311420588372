export const CommonTypes = {
  AsyncStarted: 'COMMON.ASYNC_STARTED',
  AsyncEnd: 'COMMON.ASYNC_END',
  AsyncError: 'COMMON.ASYNC_ERROR',
  ClearError: 'COMMON.CLEARERROR',
  GlobalError: 'COMMON.GLOBALERROR',
  SetCompany: 'COMMON.SETCOMPANY',
  SetBackNav: 'COMMON.SETBACKNAV',
  SetIsDisplayMobile: 'COMMON.SETISDISPLAYMOBILE',
  SetIsDisplayTablet: 'COMMON.SETISDISPLAYTABLET',
  SetOfflineMode: 'COMMON.SETOFFLINEMODE',
};
