import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import common from './Common';
import account from './Account';
import order from './Order';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    common,
    account,
    order,
  });

export default createRootReducer;
