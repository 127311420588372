import { CommonTypes } from '../types';
import ActionHelper from '../helpers/ActionHelper';

const clearError = () => {
  return {
    type: CommonTypes.ClearError,
  };
};

const setCompany = company => {
  return ActionHelper.returnAction(CommonTypes.SetCompany, company);
};

const setBackNav = pageName => {
  return ActionHelper.returnAction(CommonTypes.SetBackNav, pageName);
};

const setIsDisplayMobile = value => {
  return ActionHelper.returnAction(CommonTypes.SetIsDisplayMobile, value);
};

const setIsDisplayTablet = value => {
  return ActionHelper.returnAction(CommonTypes.SetIsDisplayTablet, value);
};

const setIsOffline = value => {
  console.log('setIsOffline', value);
  return ActionHelper.returnAction(CommonTypes.SetOfflineMode, value);
};

export const CommonAction = {
  clearError,
  setCompany,
  setBackNav,
  setIsDisplayMobile,
  setIsDisplayTablet,
  setIsOffline,
};
