import { useSelector } from 'react-redux';
import HeaderMenu from '../common/HeaderMenu';
import SettingBasic from './SettingBasic';
import SettingPopup from './SettingPopup';
import './css/SettingSwitcher.css';
import { useEffect, useState } from 'react';

const SettingSwitcher = () => {
  const common = useSelector(state => state.common);

  const [canShowPopup, setCanShowPopup] = useState(true);

  useEffect(() => {
    setCanShowPopup(true);
  }, []);

  useEffect(() => {
    setCanShowPopup(common?.IsDisplayMobile || common?.IsDisplayTablet);
  }, [common?.IsDisplayMobile, common?.IsDisplayTablet]);

  const onDoneHandle = () => {};

  const onHideHandle = () => {};

  return common?.IsDisplayMobile || common?.IsDisplayTablet ? (
    <>
      <HeaderMenu></HeaderMenu>
      <div className="setting-page">
        <SettingBasic onDone={onDoneHandle}></SettingBasic>
      </div>
    </>
  ) : (
    <SettingPopup show={canShowPopup} onHide={onHideHandle}></SettingPopup>
  );
};

export default SettingSwitcher;
