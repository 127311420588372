import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import './css/SideBarMenu.css';
import { ListGroup, Offcanvas } from 'react-bootstrap';
import ConfrimAlertMessage from '../controls/ConfrimAlertMessage';
import { AccountAction } from '../../store/actions';
import SettingPopup from '../setting/SettingPopup';
import LanguagePopup from '../language/LanguagePopup';
import { useTranslation } from 'react-i18next';

import logoutIcon from './../../contents/account/ic-logout.svg';

const SideBarMenu = ({ pathname, setShow, show }) => {
  const { t } = useTranslation();
  const [confirmAlertPopupOpened, setConfirmAlertPopupOpened] = useState(false);
  const [showSettingPopup, setShowSettingPopup] = useState(false);
  const [showLanguagesPopup, setShowLanguagesPopup] = useState(false);
  const dispatch = useDispatch();
    const navigate = useNavigate();

  const logout = () => {
    dispatch(AccountAction.logout());
    setConfirmAlertPopupOpened(false);   
    navigate("/Login"); 
  };

  const common = useSelector(state => state.common);
  const handleClose = () => setShow(false);
  const location = useLocation();
  const onChangeMenuHandle = pageName => {
    const currentPageName = location.pathname;
    if (pageName === 'Settings') {
      if (common?.IsDisplayMobile || common?.IsDisplayTablet) {
        if (currentPageName.replace('/', '') === pageName) {
          handleClose();
        } else navigate(pageName);
      } else setShowSettingPopup(true);
    } else if (pageName === 'Language') {
      if (common?.IsDisplayMobile || common?.IsDisplayTablet) {
        if (currentPageName.replace('/', '') === pageName) {
          handleClose();
        } else navigate(pageName);
      } else setShowLanguagesPopup(true);
    } else {
      console.log('currentPageName', currentPageName);
      if (currentPageName.replace('/', '') === pageName) {
        handleClose();
      } else navigate(pageName);
    }
  };
  return (
    <>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{common?.Company?.Name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup variant="flush">
            <ListGroup.Item
              as="button"
              onClick={() => onChangeMenuHandle('/Orders')}
            >
              {t('sidebar-menu-order')}
            </ListGroup.Item>
            <ListGroup.Item
              as="button"
              onClick={() => onChangeMenuHandle('Settings')}
            >
              {t('sidebar-menu-setting')}
            </ListGroup.Item>
            <ListGroup.Item
              as="button"
              onClick={() => onChangeMenuHandle('/DailyReport')}
            >
              {t('daily-report')}
            </ListGroup.Item>
            {/* <ListGroup.Item
              as="button"
              onClick={() => onChangeMenuHandle('Language')}
            >
              Language
            </ListGroup.Item> */}
          </ListGroup>

          <button
            className="logout-btn"
            onClick={() => {
              setConfirmAlertPopupOpened(true);
            }}
          >
             {t('log-out')}
            <i className="logout-logo">
              <img src={logoutIcon} alt="" />
            </i>
          </button>
        </Offcanvas.Body>
      </Offcanvas>
      {confirmAlertPopupOpened && (
        <ConfrimAlertMessage
          show={confirmAlertPopupOpened}
          yesTitle={t('yes')}
          noTitle={t('no')}
          title={t('confirmation')}
          message={t('logout-message')}
          size="sm"
          onHide={() => {
            setConfirmAlertPopupOpened(false);
          }}
          noClickedfn={() => {
            setConfirmAlertPopupOpened(false);
          }}
          yesClickedfn={() => {
            logout();
          }}
        />
      )}
      {showSettingPopup && (
        <SettingPopup
          show={showSettingPopup}
          onHide={() => {
            console.log('fired SettingPopup onHide');
            setShowSettingPopup(false);
          }}
        ></SettingPopup>
      )}

      {showLanguagesPopup && (
        <LanguagePopup
          show={showLanguagesPopup}
          onHide={() => {
            console.log('fired LanguagePopup onHide');
            setShowLanguagesPopup(false);
          }}
        ></LanguagePopup>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    pathname: state.router.location.pathname,
  };
};

export default connect(mapStateToProps)(SideBarMenu);
