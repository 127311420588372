const bsURL = `order`;

const OrderApi = {
  List: `${bsURL}/List`,
  PendingTotalCount: `${bsURL}/totalcount/pending`,
  InProgressTotalCount: `${bsURL}/totalcount/inprogress`,
  CompeletedTotalCount: `${bsURL}/totalcount/completed`,
  ScheduleTotalCount: `${bsURL}/totalcount/schedule`,

  Detail: `${bsURL}/Detail`,

  TotalCount: `${bsURL}/ListStatusCount`,

  AcceptingAnOrder: `${bsURL}/accept`,
  RejectingAnOrder: `${bsURL}/reject`,
  updateState: `${bsURL}/updatestate`,
  getOrderPrinting: `printing/output`,

  getDailyPrintOut: `Report/OrdersByPaymentMethod`,
};

export default OrderApi;
