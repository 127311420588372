export default {
  xlg: {
    screen: 1200,
  },
  lg: {
    screen: 992,
  },
  md: {
    screen: 768,
  },
  sm: {
    screen: 650,
  },
  xsm: {
    screen: 480,
  },
  xxsm: {
    screen: 320,
  },
};
