import React, { useState } from 'react';
import './css/checkbox.css';

const CustomCheckBox = ({
  id,
  label,
  name,
  className = '',
  defaultChecked,
  children,
  onChange,
  ...rest
}) => {
  const [checked, setChecked] = useState(defaultChecked);

  const onChangeInternal = (id, checked) => {
    setChecked(checked);
    if (onChange) onChange(id, checked);
  };

  return (
    <label className={`checkbox-container ${className}`}>
      {label}
      <input
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={e => onChangeInternal(id, e.currentTarget.checked)}
        type="checkbox"
        {...rest}
      />
      <span className="checkmark"></span>
      {children}
    </label>
  );
};

export default CustomCheckBox;
