import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Alert } from 'react-bootstrap';
import CustomTextbox from '../controls/CustomTextBox';
import {
  PrimaryButton as Button,
  DefaultButton as CancelButton,
} from '../controls/Button';
import AccountService from '../../services/AccountService';
import AccountAction from '../../store/actions/AccountAction';

import { emailPattern } from '../../utils/Validations';
import { CommonAction } from '../../store/actions';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
const LoginInSection = ({ onCanceled, onSuccessed, onHide }) => {
  const { t } = useTranslation();
  const [userEmailIsValid, setEmailIsValid] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = ({ email, password }) => {
    debugger;
    setPasswordIsValid(password ? true : false);
    setEmailIsValid(email ? true : false);
    if (!email || !password)
      return;
    AccountService.Login(email, password, result => {
      dispatch(AccountAction.login(result));
      dispatch(CommonAction.setCompany(result.Company));
      navigate("/");
    });
  };

  const onCanceledHandle = () => {
    if (onCanceled) {
      onCanceled();
    } else {
      if (onHide) onHide();
    }
  };

  const ononSuccessedHandle = () => {
    if (onSuccessed) {
      onSuccessed();
    } else {
      if (onHide) onHide();
    }
  };

  return (
    <div id="login">
      <Controller control={control}
        render={({ field: { onChange, onBlur, value } }) => (
      <CustomTextbox
        label={t('email-address')}
        //placeholder="Enter your email"
        type="text"
        onChange={e => {onChange(e.target.value)}}
        />)}
      name="email"
      rules={ { required: false, pattern: emailPattern }} />
      {!userEmailIsValid && (
        <Alert variant="danger">{t('email-required')}</Alert>
      )}
      {errors.email?.type === 'pattern' && (
        <Alert variant="danger">{t('email-format-incorrect')}</Alert>
      )}
      <Controller control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <CustomTextbox
            label={t('password')}
            onChange={e => onChange(e.target.value)}
            type='password'
            />)}
            name="password"
        rules={{ required: true }}
      />
      {!passwordIsValid && <Alert variant="danger">{t('password-required')}</Alert>}
      <div className="tab-content-btns">
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
        {t('login')}
        </Button>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: "30px",
        fontSize: "11px",
        color: '#929292'
      }}><span>v.1.0.40</span></div>
    </div>
  );
};

export default LoginInSection;
