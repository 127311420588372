import React from 'react';
import './css/radioButton.css';

const CustomRadioButton = ({
  id,
  label,
  name,
  className = '',
  defaultChecked,
  checked,
  children,
  onChange,
  ...rest
}) => {
  return (
    <label className={`radio-container ${className}`}>
      {label}
      <input
        name={name}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={e => onChange(id, e.currentTarget.value)}
        type="radio"
        {...rest}
      />
      <span className="checkmark"></span>
      {children}
    </label>
  );
};

export default CustomRadioButton;
