import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainLayout from '../components/layout/MainLayout';
import SettingSwitcher from '../components/setting/SettingSwitcher';

const SettingContainer = props => {
  const account = useSelector(state => state.account);

  useEffect(() => {
    if (account?.CurrentUserSession != null) {
    }
  }, [account]);
  return (
    <MainLayout>
      <SettingSwitcher></SettingSwitcher>
    </MainLayout>
  );
};

export default SettingContainer;
