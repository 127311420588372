import ApiHelper from '../store/helpers/ApiHelper';
import OrderApi from './api/Order';

const Pending = (
  queryObj = { pageSize: 10, pageIndex: 1 },
  successCallback,
  errorCallback
) => {
  queryObj['status'] = 'Active';
  return ApiHelper.getAsync({
    url: OrderApi.List,
    param: queryObj,
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    showLoading: false,
  });
};

const PendingTotalCount = (queryObj = {}, successCallback, errorCallback) => {
  return ApiHelper.getAsync({
    url: OrderApi.PendingTotalCount,
    param: queryObj,
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    showLoading: false,
  });
};

const InProgress = (
  queryObj = { pageSize: 10, pageIndex: 1 },
  successCallback,
  errorCallback
) => {
  queryObj['status'] = 'Processing';
  return ApiHelper.getAsync({
    url: OrderApi.List,
    param: queryObj,
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    showLoading: false,
  });
};

const InProgressTotalCount = (
  queryObj = {},
  successCallback,
  errorCallback
) => {
  return ApiHelper.getAsync({
    url: OrderApi.InProgressTotalCount,
    param: queryObj,
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    showLoading: false,
  });
};

const Schedule = (
  queryObj = { pageSize: 10, pageIndex: 1 },
  successCallback,
  errorCallback
) => {
  queryObj['status'] = 'Scheduled';
  return ApiHelper.getAsync({
    url: OrderApi.List,
    param: queryObj,
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    showLoading: false,
  });
};

const ScheduleTotalCount = (queryObj = {}, successCallback, errorCallback) => {
  return ApiHelper.getAsync({
    url: OrderApi.ScheduleTotalCount,
    param: queryObj,
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    showLoading: false,
  });
};

const Completed = (
  queryObj = { pageSize: 10, pageIndex: 1 },
  successCallback,
  errorCallback
) => {
  queryObj['status'] = 'Completed';
  return ApiHelper.getAsync({
    url: OrderApi.List,
    param: queryObj,
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    showLoading: false,
  });
};

const CompletedTotalCount = (queryObj = {}, successCallback, errorCallback) => {
  return ApiHelper.getAsync({
    url: OrderApi.CompletedTotalCount,
    param: queryObj,
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    showLoading: false,
  });
};

const Detail = (id, successCallback, errorCallback) => {
  return ApiHelper.getAsync({
    url: OrderApi.Detail,
    param: { ID: id },
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    showLoading: false,
  });
};

const TotalCount = (successCallback, errorCallback) => {
  return ApiHelper.getAsync({
    url: OrderApi.TotalCount,
    param: {},
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    showLoading: false,
  });
};

const AcceptOrder = (obj, successCallback, errorCallback) => {
  return ApiHelper.postAsync({
    url: OrderApi.AcceptingAnOrder,
    param: obj,
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    bodySetAsQuery: true,
    showLoading: false,
  });
};

const RejectOrder = (obj, successCallback, errorCallback) => {
  return ApiHelper.postAsync({
    url: OrderApi.RejectingAnOrder,
    param: obj,
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    bodySetAsQuery: true,
    showLoading: false,
  });
};

const UpdateStatus = (orderId, orderState, successCallback, errorCallback) => {
  return ApiHelper.postAsync({
    url: OrderApi.updateState,
    param: { orderID: orderId, orderState },
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    bodySetAsQuery: true,
    showLoading: false,
  });
};

const GetOrderPrinting = (orderId, successCallback, errorCallback) => {
  return ApiHelper.getAsync({
    url: OrderApi.getOrderPrinting,
    param: { orderID: orderId },
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    bodySetAsQuery: true,
    showLoading: true,
  });
};

const GetDailyReport = (successCallback, errorCallback) => {
  return ApiHelper.getAsync({
    url: OrderApi.getDailyPrintOut,
    param: {},
    errorCallback: errorCallback,
    afterCallback: successCallback,
    engageCompanyId: false,
    bodySetAsQuery: true,
    showLoading: true,
  });
};

const OrderService = {
  RejectOrder,
  AcceptOrder,
  Pending,
  PendingTotalCount,
  InProgress,
  InProgressTotalCount,
  Schedule,
  ScheduleTotalCount,
  Completed,
  CompletedTotalCount,
  Detail,
  UpdateStatus,
  TotalCount,
  GetOrderPrinting,
  GetDailyReport,
};

export default OrderService;
