import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import './css/ConfirmAlertMessage.css';

const ConfrimAlertMessage = ({
  title,
  message,
  yesTitle,
  noTitle,
  onHide,
  show,
  yesClickedfn,
  noClickedfn,
  size = 'lg',
}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      className="confrimpopup"
      backdrop="static"
      centered
    >
      <Modal.Body>
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="btn-group">
          <button onClick={noClickedfn} className="btn-no">
            {noTitle}
          </button>
          <button onClick={yesClickedfn} className="btn-yes">
            {yesTitle}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfrimAlertMessage;
